.url-inputs-preview {
  width: 100%;
  margin: 20px auto;

  &__img {
    display: block;
    max-height: 300px;
    max-width: 500px;
  }

  &__audio {
    margin: 10px auto;
    display: block;
  }

  &__video {
    max-width: min(100%, 1000px);
  }
}
